import { useEffect } from 'react';
import { useRouter } from 'next/router';
import HeadlineBlockTastic from '@/frontastic/tastics/static-pages/headline-block/headline-block';
import ImageContainer from '@/frontastic/tastics/image-container/image-container';
import { LinkButton } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/LinkButton';

const data = {
  _type: 'Frontastic\\Catwalk\\FrontendBundle\\Domain\\Tastic\\Configuration',
  mobile: true,
  tablet: true,
  desktop: true,
  title: "Sorry, we couldn't find that page",
  eyebrow: 'Uh oh!',
  description: 'That’s the way the cookie crumbles sometimes.',
  background: 'transparent',
  darkMode: false,
  theme: 'christie_cookies',
};

const imageData = {
  _type: 'Frontastic\\Catwalk\\FrontendBundle\\Domain\\Tastic\\Configuration',
  mobile: true,
  tablet: true,
  desktop: true,
  image: {
    media: {
      _type: 'Frontastic\\Backstage\\MediaApiBundle\\Domain\\MediaApi\\Media',
      mediaId: 'esptiav6qvbjluhtl8fr',
      resourceType: 'image',
      name: '404',
      tags: ['__none'],
      file: 'https://res.cloudinary.com/dlwdq84ig/image/upload/v1689614290/esptiav6qvbjluhtl8fr.png',
      size: 74591,
      width: 452,
      height: 355,
      format: 'png',
      created: '2023-07-17T17:18:10+00:00',
      metaData: '_FILTERED_',
    },
    title: {
      en_US: '404 image',
    },
  },
};
const Error404 = () => {
  // const router = useRouter();

  // Disabling 404 page error redirect for cc-2012
  //   useEffect(() => {
  //     router.push('/errors/404');
  //   });

  //   useEffect(() => {
  //     router.push('/errors/404', null, { shallow: true });
  //   });

  return (
    <div className="text-center">
      <ImageContainer data={imageData} />
      <HeadlineBlockTastic data={data} />
      <LinkButton palette="primary" href="/" size="md" className="mb-10 mt-5 font-normal lg:my-10">
        Continue Shopping
      </LinkButton>
    </div>
  );
};

export default Error404;
